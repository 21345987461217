import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { Table, InputGroup, FormControl, Button, Pagination, Row } from 'react-bootstrap';
import CreateShippingConfigModal from "./CreateShippingConfigModal";
import { IShippingConfigModel, IShippingConfigResponse } from "./models/ShippingConfig";
import ShippingHelper from "./ShippingHelper";
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'

export default function ShippingGrid(props: any) {

    const [tableData, setTableData] = useState({
        count: 0,
        items: [] as Array<IShippingConfigModel>
    });

    const [filteredData, setFilteredData] = useState(tableData);

    const [editingShippingConfig, setEditingShippingConfig] = useState<IShippingConfigModel | null>(null);

    const [filter, setFilter] = useState({
        textFilter: '',
        filteredData: [] as Array<IShippingConfigModel>,
    });

    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 1
    });

    const [showCreate, setShowCreate] = useState(false);

    function generatePaginationItems(items:Array<IShippingConfigModel>) {
        let paginationItems = [];
        if (items.length) {
            for (let idx = 1; idx <= Math.ceil(items.length / pagination.pageSize); idx++) {
                paginationItems.push(
                    <Pagination.Item key={idx} active={idx === pagination.page} onClick={() => setPagination({...pagination, page: idx})}>
                        {idx}
                    </Pagination.Item>,
                );
            }
        }
        return paginationItems;
    }

    async function fetchShippingConfigs() {
        ShippingHelper.loadShippingConfigurations((resp:IShippingConfigResponse) => {
            setTableData(resp);
        });
    }

    function renderTable(items:Array<IShippingConfigModel>) {
        let pageItems = [];
        let {page, pageSize} = pagination;

        for (let i = ((page-1) * pageSize)
            ; i < (page * pageSize) && i < items.length
            ; i++) {
            const s:IShippingConfigModel = items[i];
            pageItems.push((
                <tr key={s.sku!+s.options!}>
                    <td>{s.sku}</td>
                    <td>{s.options}</td>
                    <td>{s.length} cm</td>
                    <td>{s.width} cm</td>
                    <td>{s.height} cm</td>
                    <td>{s.weight} kg</td>
                    <td>
                        <FontAwesomeIcon icon={faPenToSquare} onClick={() => openCreate(s)} cursor="pointer" style={{padding:5}}/>
                        <FontAwesomeIcon icon={faTrashCan} onClick={() => deleteConfig(s)} cursor="pointer" style={{padding:5}}/>
                    </td>
                </tr>
            ));
        }

        return pageItems;
    }

    useEffect(() => {
        fetchShippingConfigs();
    }, [props]);
    

    useEffect(() => {
        let filteredData:IShippingConfigModel[] = tableData.items;

        if(filter.textFilter != '')
            filteredData = filteredData.filter(i => i.sku!.indexOf(filter.textFilter) != -1 
                || i.options?.indexOf(filter.textFilter) != -1)
        
        setFilteredData({count: filteredData.length, items: filteredData});
    }, [tableData, filter]);

    const openCreate = (data:IShippingConfigModel | null) => {
        setEditingShippingConfig(data);
        setShowCreate(true);
    }

    const deleteConfig = (data:IShippingConfigModel) => {
        ShippingHelper.deleteShippingConfiguration(data.sku, data.options, () => {
            fetchShippingConfigs();
        });
    }

    const onHideCreateModal = () => {
        setShowCreate(false)
        setEditingShippingConfig(null);
    }

    return (
        <>
            <InputGroup style={{paddingBottom:10, paddingTop:10}}>
                <FormControl
                    placeholder="Buscar"
                    aria-label="Buscar"
                    aria-describedby="basic-addon2"
                    onChange={(evt) => setFilter({...filter, textFilter: evt.target.value})}
                />
            </InputGroup>
            <div className="d-md-flex justify-content-md-end" style={{paddingBottom:10}}>
                <Button variant="primary" size="sm" onClick={() => openCreate(null)}>Nova configuração</Button>
            </div>
            <div>
                <Table striped bordered hover responsive="sm">
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>Opções</th>
                            <th>Comprimento</th>
                            <th>Largura</th>
                            <th>Altura</th>
                            <th>Peso</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (tableData.items.length > 0) && renderTable(filteredData.items)
                    }
                    {
                        (tableData.items.length == 0) &&
                        <tr>
                            <td colSpan={6}>Sem configurações a exibir...</td>
                        </tr>
                    }
                    </tbody>
                </Table>
                <Pagination size="sm" className="float-end">{generatePaginationItems(filteredData.items)}</Pagination>
            </div>

            <CreateShippingConfigModal isShowing={showCreate} 
                onHide={() => onHideCreateModal()}
                shippingConfigData={editingShippingConfig}
                onShippingUpdated={() => fetchShippingConfigs()}/>
        </>
    );
}