import React, { useEffect, useState } from "react";
import { Button, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import CatalogHelper from "./CatalogHelper";
import $ from 'jquery';
import ShippingHelper from "./ShippingHelper";
import { IUpdateShippingModelCommand, IShippingConfigModel, IShippingConfigModelOption, IShippingConfigModelChoice } from "./models/ShippingConfig";
import Helper from "./Helper";

type propTypes = {
    shippingConfigData: IShippingConfigModel | null,
    onHide: Function,
    isShowing: boolean,
    onShippingUpdated: Function
}

export default function CreateShippingConfigModal(props: propTypes) {

    const [shippingConfig, setShippingConfig] = useState<IUpdateShippingModelCommand>({});

    const [addingOptions, setAddingOptions] = useState(false);

    const [modelOptions, setModelOptions] = useState<IShippingConfigModelOption[]>([]);

    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    useEffect(() => {
        let data = props.shippingConfigData;

        if (data) {
            let editing:IUpdateShippingModelCommand = {
                length: data.length,
                height: data.height,
                width: data.width,
                weight: data.weight,
                sku: data.sku,
                options: data.options === "-" ? [] : data.options
                    .split('|')
                    .map<IShippingConfigModelOption>((s) => {
                        let opt = s.split(':');
                        return { name: opt[0], value: opt[1] };
                    })
            };
            setShippingConfig(editing);
        }
        else
            setShippingConfig({});

    }, [props.shippingConfigData]);

    useEffect(() => {
        if(shippingConfig && shippingConfig.options)
            setAddingOptions(shippingConfig.options.length != 0);
    }, [shippingConfig])

    useEffect(() => {
        const skuOptionsModelProduct: string = process.env.REACT_APP_OPTIONS_PRODUCT_MODEL || "PRODUTO_MODELO_OPCOES";

        CatalogHelper.loadProductBySKU(skuOptionsModelProduct, (data: any) => {
            setModelOptions(data.options);
        })
    }, [])

    const updateShipping = () => {
        if (validateShipping().length == 0) {
            let config:IUpdateShippingModelCommand = Helper.fastClone(shippingConfig);
            if(!addingOptions)
                delete config.options;
            ShippingHelper.saveShippingConfig(config.sku!, config, () => {
                if (props.onShippingUpdated) {
                    props.onShippingUpdated()
                }
                closeModal();
            });
        }
    }

    const updateOption = (opt: HTMLSelectElement) => {
        let newOpts = shippingConfig
            .options?.filter(o => o.name.toLowerCase() !== opt.name.toLowerCase()) ?? [];
        newOpts.push({
            name: opt.name,
            value: opt.value
        });
        setShippingConfig({...shippingConfig, options: newOpts});
    }

    const validateShipping = (): Array<string> => {
        let errorList: Array<string> = new Array<string>();

        if (!shippingConfig.sku || shippingConfig.sku === '') errorList.push(`O campo SKU é obrigatório`);
        if (!shippingConfig.length || shippingConfig.length <= 0) errorList.push(`O campo Comprimento é obrigatório`);
        if (!shippingConfig.width || shippingConfig.width <= 0) errorList.push(`O campo Largura é obrigatório`);
        if (!shippingConfig.height || shippingConfig.height <= 0) errorList.push(`O campo Altura é obrigatório`);
        if (!shippingConfig.weight || shippingConfig.weight <= 0) errorList.push(`O campo Peso é obrigatório`);

        if (addingOptions && modelOptions) {
            if (!shippingConfig.options ||
                shippingConfig.options.length != modelOptions.length ||
                shippingConfig.options.find(opt => opt.value === "0")) {
                errorList.push(`Selecionar o valor de todas as opções!`);
            }
        }

        setValidationErrors(errorList);

        return errorList;
    }

    const closeModal = () => {
        setValidationErrors([]);
        setShippingConfig({});
        setAddingOptions(false);
        props.onHide();
    }

    const getCurrentValueForOption = (name:string) => {
        if (shippingConfig && shippingConfig.options && shippingConfig.options.length != 0) {
            let currentValue = shippingConfig.options.find(o => o.name === name.toLowerCase())
            if (currentValue) {
                let opt = modelOptions.find(o => o.name === name)
                if (opt && opt.choices) {
                    let choice = opt.choices.find(c => getChoiceKey(c.text.toLowerCase()) === currentValue!.value);
                    return choice?.text;
                }
            }
        }
    }

    const getChoiceKey = (text:string) => text.replaceAll(' ', '_').toLowerCase()

    return (
        <Modal
            show={props.isShowing}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => closeModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.shippingConfigData ? "Editar Configuração" : "Criar Configuração"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} controlId="formSKU" style={{paddingBottom:5}}>
                        <Form.Label column sm="2">
                            SKU
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="CR8-X99999999" value={shippingConfig.sku}
                                onChange={(evt) => setShippingConfig({ ...shippingConfig, sku: evt.target.value })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formLength" style={{paddingBottom:5}}>
                        <Form.Label column sm="2">
                            Comprimento
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" step="1" placeholder="0 cm" value={shippingConfig.length}
                                onChange={(evt) => setShippingConfig({ ...shippingConfig, length: parseFloat(evt.target.value) })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formWidth" style={{paddingBottom:5}}>
                        <Form.Label column sm="2">
                            Largura
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" step="1" placeholder="0 cm" value={shippingConfig.width}
                                onChange={(evt) => setShippingConfig({ ...shippingConfig, width: parseFloat(evt.target.value) })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHeight" style={{paddingBottom:5}}>
                        <Form.Label column sm="2">
                            Altura
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" step="1" placeholder="0 cm" value={shippingConfig.height}
                                onChange={(evt) => setShippingConfig({ ...shippingConfig, height: parseFloat(evt.target.value) })} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formWeight" style={{paddingBottom:5}}>
                        <Form.Label column sm="2">
                            Peso
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="number" step="0.1" placeholder="0.0 kg" value={shippingConfig.weight}
                                onChange={(evt) => setShippingConfig({ ...shippingConfig, weight: parseFloat(evt.target.value) })} />
                        </Col>
                    </Form.Group>
                    <br/>
                    <Form.Check
                        type="switch"
                        id="chkAddOptions"
                        label="Adicionar opções"
                        checked={addingOptions}
                        onChange={(evt) => setAddingOptions(evt.target.checked)}
                    />
                    <br />
                    {
                        addingOptions &&
                        (
                            <div id="divOptions">
                                {modelOptions.map((opt: any) => (
                                    <Form.Group key={opt.name} as={Row} controlId={opt.name} style={{paddingBottom:5}}>
                                        <Form.Label column sm="3" style={{ textAlign: "right" }}>
                                            {opt.name}
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Select name={opt.name} value={getCurrentValueForOption(opt.name)}
                                                onChange={(evt) => updateOption(evt.target)}>
                                                <option key={0}>selecione</option>
                                                {opt.choices.map((choice: IShippingConfigModelChoice) => (
                                                    <option key={choice.text} value={choice.text}>{choice.text}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                ))}
                            </div>
                        )
                    }

                </Form>
                {
                    validationErrors.length > 0 &&
                    <ListGroup>
                        {
                            validationErrors.map(v => (
                                <ListGroup.Item key={v} variant="danger">
                                    {v}
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => closeModal()}>Fechar</Button>
                <Button variant="primary" onClick={() => updateShipping()}>Salvar</Button>
            </Modal.Footer>
        </Modal>
    );
}