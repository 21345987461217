import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

function waitForEcwid()
{
  if(EcwidApp && EcwidApp.getPayload()) render();
  else {
    setTimeout(waitForEcwid, 100);
    console.log('Waiting for ecwid to load...Did you pass the payload in url?')
  }
}

function render() {
  ReactDOM.render(
    <React.StrictMode>
      <App payload={EcwidApp.getPayload()} />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

waitForEcwid();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
