import $ from 'jquery';
import Helper from './Helper';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import Counter from './Counter';

export default class EcwidHelper {
    static API_PATH = process.env.REACT_APP_ECWID_API_URI;

    static loadCategories(payload, after) {
        let url = `${this.API_PATH}/${payload.store_id}/categories?token=${payload.access_token}`;
        $.getJSON(url, (resp) => after(resp.items));
    }

    static loadProducts(payload, list, offset, after) {
        const responseLimit = 100;
        let url = `${this.API_PATH}/${payload.store_id}/products?token=${payload.access_token}&offset=${offset}&limit=${responseLimit}&sortBy=RELEVANCE`;

        $.getJSON(url, (prods) => {
            let newList = list.concat(prods.items);
            if (prods.items.length < responseLimit)
                after(newList);
            else
                this.loadProducts(payload, newList, offset + responseLimit, after);
        });
    }

    static productExists(payload, sku, after) {
        let url = `${this.API_PATH}/${payload.store_id}/products?token=${payload.access_token}&sku=${sku}`;
        $.getJSON(url, (prods) => after(prods.items.length > 0));
    }

    static createProduct(payload, product, after, reportStatus) {
        let media = Helper.fastClone(product.media);
        let combinations = Helper.clone(product.combinations.sort((a, b) => a.combinationNumber - b.combinationNumber));
        let pdfimages = Helper.fastClone(product.pdfimages);
        let hasPDF = (pdfimages && pdfimages[0] !== undefined);
        const statusInterval = 100;
        reportStatus = reportStatus ?? ((type, status) => { });

        let removeAttribs = [`attributes`, `categories`, `categoryIds`, `media`, `combinations`, `pdfimages`];
        removeAttribs.map(attrib => delete product[attrib]);

        // Creating the product
        let url = `${this.API_PATH}/${payload.store_id}/products?token=${payload.access_token}`;
        $.post({
            url: url,
            method: 'POST',
            data: JSON.stringify(product),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        })
            .done(async (data, xhr) => {
                // Adding images
                if (!hasPDF && media && media.images) {

                    for (let idx = 0; idx < media.images.length; idx++) {
                        const image = media.images[idx];
                        reportStatus(`info`, `Adicionando imagens... (${idx}/${media.images.length})`);

                        try {
                            await this.uploadProductImage(payload, data.id, image.imageOriginalUrl, image.isMain);
                        } catch (e) {
                            let message = e.statusText ?? JSON.stringify(e);
                            reportStatus(`error`, `ERRO: ${message}`);
                            throw e;
                        }
                    }

                }
                // Adding combinations
                if (combinations) {
                    let totalCombinations = combinations.length;
                    let removeAttribs = [`attributes`];
                    let combinationsBatch = [];

                    for (let idx = 0; idx < totalCombinations; idx++) {
                        const combination = combinations[idx];
                        removeAttribs.map(attrib => delete combination[attrib]);
                        combinationsBatch.push(this.addProductCombinationRequest(idx,data.id,combination));
                    }

                    let resp = await this.executeBatch(payload, combinationsBatch);
                    await new Promise(resolve => {
                        let handler = setInterval(async () => {
                            let result = await this.getBatchResult(payload, resp.ticket);
                            if(result.status === "COMPLETED") {
                                clearInterval(handler);
                                result.responses.forEach(r => combinations[parseInt(r.id)].id = r.httpBody.id);
                                resolve();
                            } else {
                                reportStatus(`info`, `Adicionando variações em lote... (${result.completedRequests}/${result.totalRequests})`);
                            }
                        },2000)
                    });

                    // Adding variations images
                    if (hasPDF) {

                        var fr = new FileReader();
                        reportStatus(`info`, `Carregando arquivo pdf...`);
                        await new Promise((resolve) => {
                            fr.onload = () => resolve();
                            fr.readAsDataURL(pdfimages[0]);
                        });

                        let getViewPort = (page) => {
                            const width = 800, height = 800;
                            var unscaledViewport = page.getViewport({ scale: 1 });
                            var scale = Math.min((height / unscaledViewport.height), (width / unscaledViewport.width));
                            var viewport = page.getViewport({ scale: scale });
                            return viewport;
                        }

                        let promises = [], handler = null;
                        try {
                            await pdfjsLib.getDocument(fr.result).promise.then(async (pdf) => {
                                let cr8Sku = product.sku.toLowerCase().startsWith(`cr8-`);
                                let inStockCombinations = combinations.filter(c => c.inStock);
                                // if cr8, it will pass through all combinations, and upload just those who are in stock, 
                                // matching index, else, will just upload the pdf page count.
                                let total = Math.max(pdf._pdfInfo.numPages, cr8Sku ? Object.keys(combinations).length : 0); 
                                // if cr8, upload a image to each one in stock, if not, just the pdf images
                                const counter = new Counter(1, cr8Sku ? inStockCombinations.length : pdf._pdfInfo.numPages); 

                                for (let idx = 0; idx < total; idx++) {
                                    promises.push(new Promise(resolve => {
                                        if (idx < combinations.length && combinations[idx].inStock) { // there are more pdf pages than combinations.
                                            pdf.getPage((idx % pdf._pdfInfo.numPages) + 1)
                                                .then((page) => {
                                                    let viewport = getViewPort(page);
                                                    let canvas = document.createElement('canvas');
                                                    canvas.height = viewport.height;
                                                    canvas.width = viewport.width;

                                                    try {
                                                        page.render({
                                                            canvasContext: canvas.getContext('2d'),
                                                            viewport: viewport
                                                        }).promise.then(async () => {
                                                            await new Promise(() => {
                                                                canvas.toBlob((blob) => {
                                                                    if (idx === 0) this.uploadProductImageFromBlob(payload, data.id, blob, true);
                                                                    this.addCombinationImage(payload, data.id, combinations[idx].id, blob)
                                                                    .then(() => {
                                                                        counter.increment();
                                                                        resolve();
                                                                    });
                                                                }, "image/jpeg", 0.8);
                                                            });
                                                        });
                                                    } catch (e) {
                                                        let message = e.statusText ?? JSON.stringify(e);
                                                        reportStatus(`error`, `ERRO: ${message}`);
                                                        throw e;
                                                    }
                                                });
                                        }
                                        else {
                                            resolve();
                                        }
                                    }));
                                }

                                handler = setInterval(() => {
                                    reportStatus(`info`, `Importando imagem das combinações (${counter.current}/${counter.total})`);
                                }, statusInterval);
                            });

                            await Promise.allSettled(promises);
                            clearInterval(handler);
                        } catch (e) {
                            let message = e.statusText ?? JSON.stringify(e);
                            reportStatus(`error`, `ERRO: ${message}`);
                            throw e;
                        }
                    } else { // copy combinations images
                        combinationsBatch = [];

                        for (let idx = 0; idx < totalCombinations; idx++) {
                            const combination = combinations[idx];
                            if(combination.originalImageUrl)
                                combinationsBatch.push(this.addProductCombinationImageRequest(idx,data.id, combination.id, combination.originalImageUrl));
                        }

                        let resp = await this.executeBatch(payload, combinationsBatch);
                        await new Promise(resolve => {
                            let handler = setInterval(async () => {
                                let result = await this.getBatchResult(payload, resp.ticket);
                                if(result.status === "COMPLETED") {
                                    clearInterval(handler);
                                    resolve();
                                } else {
                                    reportStatus(`info`, `Importando imagens das combinações em lote (${result.completedRequests}/${result.totalRequests})`);
                                }
                            },2000)
                        });
                    }
                }
                reportStatus(`info`, null);
                after(true, data);
            })
            .fail((data, xhr) => {
                after(false, data.responseJSON.errorMessage);
            });
    }

    static async uploadProductImage(payload, productId, imageUrl, mainImage) {
        let url = `${this.API_PATH}/${payload.store_id}/products/${productId}/${mainImage ? 'image' : 'gallery'}?token=${payload.access_token}&externalUrl=${encodeURIComponent(imageUrl)}`;
        return $.post({
            url: url,
            method: 'POST'
        });
    }

    static async uploadProductImageFromBlob(payload, productId, image, mainImage) {
        let url = `${this.API_PATH}/${payload.store_id}/products/${productId}/${mainImage ? 'image' : 'gallery'}?token=${payload.access_token}`;
        return $.post({
            url: url,
            method: 'POST',
            data: image,
            dataType: "json",
            processData: false,
            contentType: "application/octet-stream",
        });
    }

    static async addProductCombination(payload, productId, combination) {
        let url = `${this.API_PATH}/${payload.store_id}/products/${productId}/combinations?token=${payload.access_token}`;
        return $.post({
            url: url,
            method: 'POST',
            data: JSON.stringify(combination),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        });
    }

    static async addCombinationImage(payload, productId, combinationId, image) {
        let url = `${this.API_PATH}/${payload.store_id}/products/${productId}/combinations/${combinationId}/image?token=${payload.access_token}`;
        return $.post({
            url: url,
            method: 'POST',
            data: image,
            dataType: "json",
            processData: false,
            contentType: "application/octet-stream",
        });
    }

    static async executeBatch(payload, batch) {
        let url = `${this.API_PATH}/${payload.store_id}/batch/?token=${payload.access_token}`;
        return $.post({
            url: url,
            method: 'POST',
            data: JSON.stringify(batch),
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        });
    }

    static async getBatchResult(payload, ticket) {
        let url = `${this.API_PATH}/${payload.store_id}/batch?token=${payload.access_token}&ticket=${ticket}`;
        return $.get({ url: url });
    }

    static addProductCombinationRequest(id, productId, combination) {
        return {
            id: id,
            path: `/products/${productId}/combinations`,
            method: 'POST',
            body: combination
        }
    }

    static addProductCombinationImageRequest(id, productId, variationId, imageUrl) {
        return {
            id: id,
            path: `/products/${productId}/combinations/${variationId}/image?externalUrl=${encodeURIComponent(imageUrl)}`,
            method: 'POST'
        }
    }
}