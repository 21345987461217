import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import './ModalCreate.css';
import Helper from "./Helper";
import EcwidHelper from "./EcwidHelper";
import "dropzone/dist/dropzone.css";
import BasicDropZone from './BasicDropZone';

export default class ModalCreate extends PureComponent {
    static propTypes = {
        payload: PropTypes.object,
        productData: PropTypes.object,
        displaying: PropTypes.bool,
        handleCreateCanceled: PropTypes.func,
        handleCreateConfirmed: PropTypes.func,
        payload: PropTypes.object
    };

    static currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
    static numberFormatter = new Intl.NumberFormat('en-US', { style: 'decimal' });
    static initialProfit = "R$ 0,00 (0%)"

    constructor(props) {
        super(props);
        this.state = {
            original: null,
            current: null,
            info: null,
            processing: false,
            errors: []
        }
    }

    isDefaultStore = () => process.env.REACT_APP_DEFAULT_STORE === this.props.payload.store_id.toString()
        || process.env.REACT_APP_ADMIN;

    componentDidMount() {
    }

    componentDidUpdate() {
        if (this.props.productData) {
            if ((this.state.original !== this.props.productData)) {
                let newProductData = Helper.clone(this.props.productData);
                let combinations = newProductData.combinations.sort((a, b) => a.combinationNumber - b.combinationNumber);
                newProductData.combinations = combinations.map(c => {
                    c.number = c.combinationNumber;
                    c.suffix = "";
                    c.originalPrice = c.price;
                    c.profit = ModalCreate.initialProfit
                    return c;
                });
                newProductData = { ...newProductData, suffix: "", originalPrice: newProductData.price, profit: ModalCreate.initialProfit };
                this.setState({
                    original: this.props.productData,
                    current: newProductData,
                    processing: false,
                    info: null,
                    errors: []
                });
            }
        }
    }

    updateName(name) {
        let newProductData = Helper.fastClone(this.state.current);
        newProductData.name = name;
        if (newProductData.nameTranslated)
            Object.keys(newProductData.nameTranslated).forEach(k => newProductData.nameTranslated[k] = name);
        this.setState({ current: newProductData });
    }

    updatePrice = (evt) => {
        let newProductData = Helper.fastClone(this.state.current);
        const newValue = parseFloat(evt.target.value);
        newProductData.price = newValue;
        newProductData.profit = this.getEarnings(this.props.productData.price, newValue);
        this.setState({ current: newProductData });
    }

    updateSKUs = (suffix) => {
        let newProductData = Helper.fastClone(this.state.current);
        newProductData.combinations = newProductData.combinations.map(c => {
            c.suffix = suffix;
            return c;
        });
        newProductData.suffix = suffix;
        this.setState({ current: newProductData });
    }

    updateBaseSKU = (newSku) => {
        if (newSku.substring(newSku.length - 1) === '-') newSku = newSku.substring(0, newSku.length - 1);
        let newProductData = Helper.fastClone(this.state.current);
        newProductData.combinations = newProductData.combinations.map(c => {
            let group = c.sku.split('-');
            c.sku = newSku + '-' + group[group.length - 1];
            return c;
        });
        newProductData.sku = newSku;
        this.setState({ current: newProductData });
    }

    updateVariationPrice = (sku, price) => {
        let newProductData = Helper.fastClone(this.state.current);
        newProductData.combinations = newProductData.combinations.map(c => {
            if (c.sku === sku) {
                c.price = parseFloat(price);
                c.profit = this.getEarnings(c.originalPrice, c.price);
            }
            return c;
        });
        let minimumPrice = newProductData.combinations.map(c => c.price).sort((a,b) => a-b)[0];
        if (newProductData.price < minimumPrice) {
            newProductData.price = minimumPrice;
            newProductData.profit = this.getEarnings(this.props.productData.price, newProductData.price);
        }
        this.setState({ current: newProductData });
    }

    updateVariationsPriceBy = (type) => {
        let percentual = type==="percentual";
        let signal = document.getElementById(percentual ? "selSinalPercentual":"selSinalAbsoluto").value === "+";
        let value = parseFloat(document.getElementById(percentual ? "modificadorPercentual":"modificadorAbsoluto").value);
        let newProductData = Helper.clone(this.state.current);
        newProductData.combinations = newProductData.combinations.map(c => {
            c.price = c.price +
                ((percentual) ?
                    (c.price * (parseFloat(value)/100) * (signal ? 1 : -1))
                  : (parseFloat(value) * (signal ? 1 : -1)));
            let round = document.getElementById("selArredondar").value;
            if(round !== "não") {
                c.price = Math.round(c.price * (1/parseFloat(round))) / (1/parseFloat(round))
            }
            c.profit = this.getEarnings(c.originalPrice, c.price);
            return c;
        });
        let minimumPrice = newProductData.combinations.map(c => c.price).sort((a,b) => a-b)[0];
        if (newProductData.price < minimumPrice) {
            newProductData.price = minimumPrice;
            newProductData.profit = this.getEarnings(this.props.productData.price, newProductData.price);
        }        
        this.setState({ current: newProductData });
    }

    getEarnings = (basePrice, sellPrice) => {
        let profit = sellPrice - basePrice;
        let markup = (profit / basePrice) * 100;
        return `${ModalCreate.currencyFormatter.format(profit)} (${Math.floor(markup)}%)`;
    }

    createProduct = () => {
        if (this.validateProduct()) {
            var sku = this.state.current.sku + (this.state.current.suffix !== '' ? '-' + this.state.current.suffix : '');
            const reportStatus = (type, status) => {
                if (type === `info`)
                    this.setState({ info: status });
                else if (type === `error`) {
                    this.setState({
                        errors: [...this.state.errors, status],
                        processing: false
                    });
                }
            };

            // validate if product exists
            EcwidHelper.productExists(this.props.payload, sku, (exists) => {
                if (!exists) {
                    this.setState({ processing: true });
                    reportStatus('info', 'Criando produto...');
                    var product = Helper.fastClone(this.state.current);
                    product.combinations = Helper.clone(product.combinations);
                    var oldSku = product.sku;
                    product.sku = sku;
                    if (product.combinations) {
                        for (const idx in product.combinations) {
                            var comb = product.combinations[idx];
                            comb.sku = comb.sku + (comb.suffix !== '' ? '-' + comb.suffix : '')
                        }
                    }
                    // create product
                    EcwidHelper.createProduct(this.props.payload, product, (created, result) => {
                        if (created) {
                            this.setState({ original: null });
                            this.props.handleCreateConfirmed(product);
                        }
                        else {
                            this.setState({
                                errors: [result],
                                current: { ...product, sku: oldSku }
                            });
                        }
                    }, reportStatus);
                }
                else {
                    this.setState({ errors: ['O sku informado já existe na loja.'] });
                }
            });
        }
    }

    cancelCreation = () => {
        this.props.handleCreateCanceled();
        this.setState({ original: null });
    }

    validateProduct = () => {
        const o = this.state.current;
        let errors = [];
        if (o.name === "") errors.push('O nome do produto que será criado é obrigatório.');
        if (!this.isDefaultStore() && o.suffix === "") errors.push('O sufixo personalizado do produto é obrigatório.');
        if (o.price < o.originalPrice) errors.push('O preço final do produto deve ser maior ou igual ao preço de custo do mesmo.');
        if (o.combinations.some(c => c.price < c.originalPrice)) errors.push('O preço final de cada combinação deve ser maior ou igual ao preço de custo da mesma.');
        this.setState({ errors: errors });
        return errors.length === 0;
    }

    filesChanged = (files) => {
        this.setState({
            current: { ...this.state.current, pdfimages: files }
        });
    }

    handleSuffixKeyDown = (evt) => {
        if (evt.key === "-") {
            evt.preventDefault();
        }
    }

    handleSuffixChange = (evt) => {
        evt.target.value = evt.target.value.replaceAll('-','');
        this.updateSKUs(evt.target.value);
    }

    render() {
        if (this.state.original == null)
            return (<div className="modal" id="modalCreate" style={{ display: (this.props.displaying ? 'block' : 'none') }}>
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Carregando...</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.cancelCreation}></button>
                        </div>
                    </div>
                </div>
            </div>);

        return (
            <div className="modal" id="modalCreate" style={{ display: (this.props.displaying ? 'block' : 'none') }}>
                <div className="modal-dialog  modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Criar produto</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.cancelCreation}></button>
                        </div>
                        <div className="modal-body">
                            <h6>Produto escolhido</h6>
                            <div className="row mb-1">
                                <div className="col-md-4">
                                    <div className="imageContainer">
                                        <img className="image" src={this.state.original.imageUrl} alt={this.state.original.name}></img>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <h6>SKU: {this.state.original.sku}</h6>
                                    <h6>Nome: {this.state.original.name}</h6>
                                    <h6>Preço de custo: {ModalCreate.currencyFormatter.format(this.state.original.price)}</h6>
                                </div>
                            </div>
                            <h6>Parâmetros de criação</h6>
                            <div className="row mb-1">
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader">SKU</span>
                                    <input type="text" onChange={(o) => this.updateBaseSKU(o.target.value)} defaultValue={this.state.original.sku + "-"} className="input-group-text" disabled={!this.isDefaultStore()} />
                                    <input type="text" onChange={this.handleSuffixChange} defaultValue={this.state.current.suffix} onKeyDown={this.handleSuffixKeyDown} className="form-control" placeholder="Sufixo" />
                                </div>
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader">Preço</span>
                                    <input type="number" min={this.state.original.price}
                                        className="form-control"
                                        placeholder="Preço final" value={this.state.current.price}
                                        onChange={(evt) => this.updatePrice(evt)} step="0.01" />
                                    <span className="input-group-text">lucro: {this.state.current.profit}</span>
                                </div>
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader">Nome</span>
                                    <input type="text" className="form-control" placeholder="Nome"
                                        defaultValue={this.state.original.name} onChange={(evt) => this.updateName(evt.target.value)} step="0.01" />
                                </div>
                            </div>
                            <h6>Modificar preços das variações</h6>
                            <div className="row mb-1">
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader" style={{minWidth:160}}>percentualmente</span>
                                    <select id="selSinalPercentual" defaultValue="+" style={{minWidth:50, textAlign:"center"}}>
                                        <option value="+">+</option>
                                        <option value="-">-</option>
                                    </select>
                                    <input id="modificadorPercentual" type="number" min="0" step="0.01" defaultValue="0" className="input-group-text back-white" />
                                    <button onClick={(o) => this.updateVariationsPriceBy("percentual")}>Aplicar</button>
                                </div>
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader" style={{minWidth:160}}>em valores</span>
                                    <select id="selSinalAbsoluto" defaultValue="+" style={{minWidth:50, textAlign:"center"}}>
                                        <option value="+">+</option>
                                        <option value="-">-</option>
                                    </select>
                                    <input id="modificadorAbsoluto" type="number" min="0" step="0.01" defaultValue="0" className="input-group-text back-white" />
                                    <button onClick={(o) => this.updateVariationsPriceBy("absolute")}>Aplicar</button>
                                </div>
                                <div className="input-group mb-1">
                                    <span className="input-group-text btn-primary formHeader" style={{minWidth:160}}>arredondar</span>
                                    <select id="selArredondar" defaultValue="não" style={{minWidth:50, textAlign:"center"}}>
                                        <option value="não">não</option>
                                        <option value="1">1.00</option>
                                        <option value="0.1">0.10</option>
                                        <option value="0.01">0.01</option>
                                    </select>
                                </div>                                 
                            </div>
                            <h6>Variações</h6>
                            <div className="container">
                                {this.state.current.combinations ?
                                    this.state.current.combinations.map(c => (
                                        <div className="row mb-1" key={c.number} style={{ border: "1px solid lightgrey", borderRadius: 10, paddingTop: 10, paddingBottom: 10 }}>
                                            <div className="col-sm-6 col-md-8">
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text">#{c.number}</span>
                                                </div>
                                                {c.sku &&
                                                    <div className="input-group mb-1">
                                                        <span className="input-group-text btn-primary formHeader">SKU</span>
                                                        <span className="input-group-text">{c.sku}{this.state.current.suffix !== "" ? "-" + this.state.current.suffix : ""}</span>
                                                    </div>
                                                }
                                                <div className="input-group mb-1">
                                                    <span className="input-group-text btn-primary formHeader">Preço</span>
                                                    <input type="number" min={this.state.original.price}
                                                        className="form-control"
                                                        placeholder="Preço final" value={c.price}
                                                        onChange={(o) => this.updateVariationPrice(c.sku, o.target.value)} step="0.01" />
                                                    <span className="input-group-text">lucro: {c.profit}</span>
                                                </div>
                                                <div>
                                                    {c.options && c.options.map(o => (
                                                        <span key={o.name}><span className="badge rounded-pill bg-success option-badge">{o.name}: {o.value}</span>&nbsp;</span>
                                                    ))
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 img-box">
                                                <img src={c.smallThumbnailUrl ?? "/img/no-img.png"} alt=""></img>
                                            </div>
                                        </div>
                                    ))
                                    : ""
                                }
                            </div>
                            <br />
                            {(!this.state.current.sku.toLowerCase().startsWith(`cat-`) // skus that starts with cat doesn't need uploading an image
                                || this.isDefaultStore()) // if the store is the model, it should appear always
                                && 
                                <div>
                                    <h6>Imagens</h6>
                                    <div className="row mb-1">
                                        <BasicDropZone key="dropZone" onFilesChanged={this.filesChanged} />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="modal-footer">
                            <div className="container">
                                {this.state.info &&
                                    <div key="info" className="alert alert-success" role="alert" style={{ padding: 10, marginBottom: '0.2rem' }}>{this.state.info}</div>
                                }
                            </div>
                            <div className="container">
                                {this.state.errors.map((e, i) => (
                                    <div key={i} className="alert alert-danger" role="alert" style={{ padding: 10, marginBottom: '0.2rem' }}>{e}</div>
                                ))}
                            </div>
                            {!this.state.processing && <div>
                                <button className="btn" onClick={this.cancelCreation}>Cancelar</button>
                                <button className="btn btn-primary" onClick={this.createProduct} >Criar</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}