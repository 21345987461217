import './ProductGridItem.css';
import React, { PureComponent } from "react";
import PropTypes from "prop-types";

export default class ProductGridItem extends PureComponent {
  static propTypes = {
    id: PropTypes.number,
    sku: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.number,
    imageUrl: PropTypes.string,
    buttonText: PropTypes.string,
    buttonClick: PropTypes.func,
    productData: PropTypes.object
  };

  handleClick = (event) => {
    this.props.buttonClick(this.props.productData);
  }

  render() {
    var desc = "[" + this.props.productData.sku + "]\r\n" + this.props.productData.name;
    return (
      <div className="col-md-2 productSpace">
        <div productid={this.props.productData.id} originalname={this.props.productData.name} sku={this.props.productData.sku} name={desc} className="thumbnail">
          <img src={this.props.productData.smallThumbnailUrl ?? '/img/no-img.png'} alt={desc} style={{ maxWidth: '140px' }} />
          <div className="boxOverlay">
            <div className="caption text-center productBox"><small>{this.props.productData.name}</small></div>
            <div className="middleBox">
              <button className="btn btn-primary" onClick={this.handleClick}>{this.props.buttonText}</button>
            </div>
            <div className="caption text-center productBox"><small>[{this.props.productData.sku}]</small></div>
          </div>
        </div>
      </div>
    );
  }
}
