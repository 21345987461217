import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ModalCreate from './ModalCreate';
import ProductGrid from './ProductGrid';
import CatalogHelper from "./CatalogHelper";
import PropTypes from "prop-types";
import { Toast, ToastContainer, Tabs, Tab, TabPane, TabContent } from "react-bootstrap";
import React, { PureComponent } from "react";
import ShippingGrid from './ShippingGrid';

export default class App extends PureComponent {

  static propTypes = {
    payload: PropTypes.object,
    productsData: PropTypes.array,
    modalDisplaying: PropTypes.bool,
    modalSelectedProduct: PropTypes.object,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    categories: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      payload: this.props.payload,
      modalDisplaying: false,
      modalSelectedProduct: null,
      productsData: null,
      successMessage: null,
      errorMessage: null,
      categories: null,
      categoriesDict: null
    }
  }

  componentDidMount() {
    this.initializeData();
  }
  
  componentDidUpdate() {}

  initializeData = () => {
    let payload = this.state.payload;
    CatalogHelper.loadCategories(payload.store_id, payload.access_token, (cats) => {
      let catDict = {};
      cats.forEach(elem => {
        catDict[elem.id] = elem.name
      });
      this.setState({
        categories: cats,
        categoriesDict: catDict
      })
    });
    CatalogHelper.loadProducts(payload.store_id, payload.access_token, (prods) => {
      this.setState({
        productsData: prods
      })
    });
  }

  handleCreate = (p) => {
    this.setState({ modalDisplaying: true });
    CatalogHelper.loadProduct(p.id, (fullProduct) => {
      this.setState({ modalSelectedProduct: fullProduct });
    })
  }

  handleCreateConfirmed = (event) => {
    this.setState({
      modalDisplaying: false,
      modalSelectedProduct: null,
      successMessage: "Produto criado com sucesso!"
    });
  }

  handleCreateCanceled = (event) => {
    this.setState({
      modalDisplaying: false,
      modalSelectedProduct: null
    });
  }

  isDefaultStore = () => process.env.REACT_APP_DEFAULT_STORE === this.props.payload.store_id.toString()
    || process.env.REACT_APP_ADMIN;

  defaultVars = () => {
    return {
      "vars": [
        process.env.REACT_APP_ECWID_API_URI,
        process.env.REACT_APP_BACKEND_API_URI,
        process.env.REACT_APP_DEFAULT_STORE,
      ]
    }
  }

  render() {
    return (
      <div className="App">
        <header />
        <ToastContainer position="bottom-end">
          <Toast className="toast text-white bg-success" onClose={() => this.setState({ successMessage: null })} show={this.state.successMessage !== null} delay={3000} autohide>
            <Toast.Body>{this.state.successMessage}</Toast.Body>
          </Toast>
          <Toast className="toast text-white bg-danger" onClose={() => this.setState({ errorMessage: null })} show={this.state.errorMessage !== null} delay={3000} autohide>
            <Toast.Body>{this.state.errorMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
        {this.state.productsData == null ?
          <div style={{ padding: '3vw', textAlign: 'center' }} className="container-fluid">
            <h3>carregando...</h3>
          </div>
          :
          <div style={{ padding: '3vw' }} className="container-fluid">
            <Tabs defaultActiveKey="products" className="mb-3 menuTabs">
              <Tab eventKey="products" title="Produtos">
                <TabPane>
                  <TabContent>
                    <ProductGrid productsData={this.state.productsData} categories={this.state.categories} categoriesDict={this.state.categoriesDict} handleCreate={this.handleCreate} />
                    <ModalCreate productData={this.state.modalSelectedProduct} displaying={this.state.modalDisplaying}
                      handleCreateConfirmed={this.handleCreateConfirmed} payload={this.state.payload} handleCreateCanceled={this.handleCreateCanceled} />
                  </TabContent>
                </TabPane>
              </Tab>

              {this.isDefaultStore() &&
              <Tab eventKey="shipping" title="Frete">
                <TabPane>
                  <TabContent>
                    <ShippingGrid />
                  </TabContent>
                </TabPane>
              </Tab>}
            </Tabs>
          </div>
        }
      </div>
    );
  }
}
