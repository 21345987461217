import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useDropzone } from 'react-dropzone';

export default function BasicDropZone(props) {

    BasicDropZone.propTypes = {
        onFilesChanged: PropTypes.func
    }

    const [myFiles, setMyFiles] = useState([])
    const onFilesChanged = props.onFilesChanged;

    const onDrop = (acceptedFiles) => {
        setMyFiles([...myFiles, ...acceptedFiles])
        if(onFilesChanged) onFilesChanged(acceptedFiles);
    }

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        maxFiles: 2
    });

    const removeFile = (file) => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
        if(onFilesChanged) onFilesChanged(newFiles);
    }

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })} style={{ textAlign: 'center', display: 'flex',  justifyContent: 'center'
            , alignItems: 'center', height: '100px'}}>
                <input {...getInputProps()} />
                {myFiles.length === 0 &&
                    <div>
                        <p>Clique aqui para selecionar um arquivo com as imagens...</p>
                        <em>(somente arquivos .pdf são aceitos)</em>
                    </div>
                }
                {myFiles.length === 1 &&
                    <div>
                        <p>{acceptedFiles[0].name}</p>
                    </div>
                }
            </div>
            <div style={{ textAlign: "right", marginBottom: '10px' }}>
            {
                myFiles.length === 1 &&
                    <button type="button" className="btn btn-link" onClick={removeFile}>limpar</button>
            }
            </div>
        </section>
    );
}