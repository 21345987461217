import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ProductGridItem from "./ProductGridItem";
import './ProductGrid.css';
import jQuery from "jquery";
import Helper from "./Helper";

export default class ProductGrid extends PureComponent {
  static propTypes = {
    productsData: PropTypes.array,
    categories: PropTypes.array,
    categoriesDict: PropTypes.object,
    filters: PropTypes.object,
    handleCreate: PropTypes.func
  };

  initialFilterValues = () => {
    return {
      textFilter: "",
      pageSize: 50,
      current: 1,
      total: this.props.productsData.length,
      sortBy: `ASC`,
      categories: [],
      priceMin: 0,
      priceMax: 999,
      categoriesThatMatch: []
    };
  };

  constructor(props) {
    super(props);

    let filteredData = this.filterData(this.props.productsData, this.initialFilterValues());
    this.state = {
      data: filteredData,
      handleTextSearch: null,
      filters: this.initialFilterValues()
    }
  }

  updateResults = (filters) => {
    var dataFiltered = this.filterData(this.props.productsData, filters);
    this.setState({
      data: dataFiltered,
      filters: { ...filters, total: dataFiltered.length }
    })
  }

  updateFilterAndResults = (filters) => {
    this.setState({
      filters: filters
    });
    this.updateResults(filters);
  }

  localeIncludes = (text, substring) => {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .includes(substring.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  }

  filterData = (data, filters) => {
    if (!filters) return data;
    return data.filter(p => {
      let filteringCategories = filters.categories.length !== 0;
      if (filteringCategories &&
        p.categoryIds.filter(id => filters.categoriesThatMatch.indexOf(id) !== -1).length === 0) return null;
      if (filters.textFilter) {
        let txt = filters.textFilter.toLowerCase();
        let name = p.name?.toLowerCase();
        let sku = p.sku?.toLowerCase();
        if (!(this.localeIncludes(name, txt) || this.localeIncludes(sku, txt))) return null;
      }
      if (filters.priceMin && p.price < filters.priceMin) return null
      if (filters.priceMax && p.price > filters.priceMax) return null
      return p;
    });
  }

  handleCreate = (p) => {
    this.props.handleCreate(p);
  }

  handleLimparFiltros = () => this.updateFilterAndResults(this.initialFilterValues());

  handleChangePropTextFilter = (evt) => {
    if (this.state.handleTextSearch) clearTimeout(this.state.handleTextSearch);
    this.setState({
      filters: { ...this.state.filters, textFilter: jQuery(evt.target).val() }
    });
    let handle = setTimeout(() => {
      this.setState({
        handleTextSearch: null,
      });
      this.updateResults(this.state.filters);
    }, 200);
    this.setState({
      handleTextSearch: handle
    });
  };

  handleChangePropMinPrice = (evt) => this.updateFilterAndResults({ ...this.state.filters, priceMin: jQuery(evt.target).val() });
  handleChangePropMaxPrice = (evt) => this.updateFilterAndResults({ ...this.state.filters, priceMax: jQuery(evt.target).val() });
  handleAddFiltroCategoria = (evt) => {
    let target = jQuery(evt.target);
    let id = target.val();
    if (id) {
      if (this.state.filters.categories.indexOf(id) === -1) {
        let newFilters = Helper.fastClone(this.state.filters);
        newFilters.categories.push(id);
        this.handleBuildCategoriesThatMatch(newFilters);
        this.updateFilterAndResults(newFilters);
      }
      setTimeout(() => target.val(""), 10);
    }
  };

  handleRemoveFiltroCategoria = (evt) => {
    let target = jQuery(evt.target);
    let id = target.attr("id");
    let newFilters = Helper.fastClone(this.state.filters);
    newFilters.categories = newFilters.categories.filter(c => c !== id);
    this.handleBuildCategoriesThatMatch(newFilters);
    this.updateFilterAndResults(newFilters);
  };

  handleBuildCategoriesThatMatch(newFilters) {
    newFilters.categoriesThatMatch = [];
    newFilters.categories.forEach(id => {
      newFilters.categoriesThatMatch =
        newFilters.categoriesThatMatch.concat(
          this.props.categories.filter(cat => cat.breadcrumbId.includes(id.toString())).map(cat => cat.id));
    });
  }

  handleLimparTexto = () => this.updateFilterAndResults({ ...this.state.filters, textFilter: '' });

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body" style={{paddingBottom: 0}}>
            <div className="form-row g-3" style={{ display: "flex", textAlign: "center" }}>
              <div className="form-group">
                <div className="input-group">
                  <input type="text" className="form-control" value={this.state.filters.textFilter} size={40} placeholder="Buscar..." onChange={this.handleChangePropTextFilter} />
                  <button className="btn btn-primary" type="button" onClick={this.handleLimparTexto}>limpar</button>
                </div>
              </div>
              <span style={{ marginLeft: 10 }}></span>
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">Preço</span>
                  <input type="number" className="form-control" min={0} value={this.state.filters.priceMin} aria-describedby="basic-addon1" style={{ textAlign: "center" }} onChange={this.handleChangePropMinPrice} />
                  <span className="input-group-text" id="basic-addon1">a</span>
                  <input type="number" className="form-control" value={this.state.filters.priceMax} aria-describedby="basic-addon1" style={{ textAlign: "center" }} onChange={this.handleChangePropMaxPrice} />
                </div>
              </div>
              <span style={{ marginLeft: 10 }}></span>
              <div className="form-group">
                <div className="input-group">
                  <button type="button" className="form-control btn btn-primary" onClick={this.handleLimparFiltros}>limpar filtros</button>
                </div>
              </div>
            </div>
            <br />
            <div className="row mb-3">
              <div className="form-group">
                <div className="input-group">
                  <select className="form-control form-select" aria-label="filtrar categoria" defaultValue={""} style={{ minWidth: "20em", maxWidth: "30em" }} onChange={this.handleAddFiltroCategoria}>
                    <option value="">filtrar categoria</option>
                    {(this.props.categories ?? []).map(cat =>
                    (
                      <option value={cat.id} key={cat.id}>{this.props.categoriesDict[cat.id]}</option>
                    )
                    )}
                  </select>
                </div>
              </div>

            </div>
          </div>
          <div style={{ display: "inline-flex", padding: 0, marginLeft: 15, marginRight: 15 }}>
            {this.state.filters.categories.map(id =>
              <span key={id} className="badge rounded-pill bg-secondary category-filter">categoria:&quot;{this.props.categoriesDict[id]}&quot;&nbsp;<span className="badge rounded-pill bg-dark remove-filter-button" title="remover filtro" key={id} id={id} onClick={this.handleRemoveFiltroCategoria}>X</span></span>
            )}
          </div>
          <div style={{ display: "inline-flex", padding: 0, marginLeft: 15, marginRight: 15 }}>
            <h6 className="" style={{ width: "100%", textAlign: "right" }}>Mostrando {this.state.filters.total} de {this.props.productsData.length}</h6>
          </div>
        </div>
        <div className="card" style={{marginTop:10}}>
          <div className="card-body">
            <div style={{ maxHeight: '400px', overflowY: 'auto', display: 'inline-flex', padding: 20, width: '100%' }} className="row">
              {this.state.data.map(p => {
                return (<ProductGridItem key={p.id} buttonText="Criar" buttonClick={this.handleCreate} productData={p} />);
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}
