import $ from 'jquery';

export default class CatalogHelper {
    static API_PATH = process.env.REACT_APP_BACKEND_API_URI;

    static loadProducts(storeId, token, afterLoading) {
        var url = `${this.API_PATH}/catalog?store_id=${storeId}&token=${token}`;

        $.getJSON(url, (prods) => {
            afterLoading(prods);
        });
    }

    static loadProduct(productId, afterLoading) {
        var url = `${this.API_PATH}/catalog/${productId}`;

        $.getJSON(url, (p) => {
            afterLoading(p);
        });
    }

    static loadCategories(storeId, token, afterLoading) {
        var url = `${this.API_PATH}/catalog/categories?store_id=${storeId}&token=${token}`;

        $.getJSON(url, (prods) => {
            afterLoading(prods);
        });
    }

    static loadProductBySKU(productSKU, afterLoading) {
        var url = `${this.API_PATH}/catalog/sku/${productSKU}`;

        $.getJSON(url, (p) => {
            afterLoading(p);
        });
    }
}