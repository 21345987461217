import $ from 'jquery';
import { IShippingConfigModel, IShippingConfigResponse, IUpdateShippingModelCommand } from './models/ShippingConfig';

export default class ShippingHelper {
    static API_PATH = process.env.REACT_APP_BACKEND_API_URI;

    static async loadShippingConfigurations(afterLoading:Function) {
        //let url = `${API_PATH}/shipping_config?filter=${filter.textFilter}&pageSize=${pagination.pageSize}&page=${pagination.page}`;
        let url = `${ShippingHelper.API_PATH}/shipping_config`;
        let configs = await fetch(url, {
            method: 'GET',
            headers: { 'content-type': 'application/json;charset=UTF-8' }
        });

        let resp = await fetch(url);
        let data:IShippingConfigResponse = await resp.json();
        afterLoading(data);
    }

    static async saveShippingConfig(sku:string, shippingConfig:IUpdateShippingModelCommand, afterSaving:Function) {
        let url = `${ShippingHelper.API_PATH}/shipping_config/${sku}`;


        let resp = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(shippingConfig)
        })

        if(afterSaving)
            afterSaving(resp);
    }

    static async deleteShippingConfiguration(sku:string, options:string, afterDeleting:Function) {
        let url = `${ShippingHelper.API_PATH}/shipping_config/${encodeURIComponent(sku)}?options=${encodeURIComponent(options)}`;

        let resp = await fetch(url, {
            method: 'DELETE'
        })

        if(afterDeleting)
            afterDeleting(resp);
    }
}